import "leaflet/dist/leaflet.css";
import * as React from "react";
import { AppContext } from "./src/context/app-context";
import { PageContext } from "./src/context/page-context";
import "./src/declaration.d.ts";
import "./src/styles/global.css";

window.addEventListener("load", () => {
    // window.scrollTo(0, 0);
    setTimeout(() => {
        document.documentElement.classList.add("initialized");
    }, 3000);
});

// window.addEventListener("scroll", (event) => {
//     console.log(event, window.scrollY);
//     console.trace();
// });
// console.log("events added");

export const wrapRootElement = ({ element }) => {
    return <AppContext>{element}</AppContext>;
};

export const wrapPageElement = ({ element, props }) => {
    let monthParam = null;
    if (window?.location?.search) {
        const urlParams = new URLSearchParams(window.location.search);
        monthParam = urlParams.get("month");
        var yourCurrentUrl = window.location.href.split("?")[0];
        window.history.replaceState({}, "", yourCurrentUrl);
    }

    return (
        <PageContext month={monthParam || props?.pageContext?.month}>
            {element}
        </PageContext>
    );
};
// export const shouldUpdateScroll = ({
//     routerProps: { location },
//     getSavedScrollPosition
// }) => {
//     const currentPosition = getSavedScrollPosition(location);
//     if (!location.hash) {
//         setTimeout(() => {
//             window &&
//                 window.scrollTo({
//                     top: currentPosition[0] || 0,
//                     left: currentPosition[1] || 0,
//                     behavior: "instant"
//                 });
//         }, 0);
//         return false;
//     }
// };

// export const onRouteUpdate = ({ location }) => {
//     const { pathname, key, hash } = location;
//     const data = {
//         pathname,
//         key,
//         hash,
//         baseScroll: scrollY,
//         scroll: window.scrollY,
//         width: window.innerWidth,
//         height: window.innerHeight
//     };
//     sendBeacon(data);
// };
