import * as React from "react";
import { createContext, useCallback, useEffect } from "react";
import { sendBeacon } from "../services/beacons";

const initialValues = {
    lang: "en",
    temp: "C"
};

export const AppContext = ({ children }: { children: React.ReactNode }) => {
    const [language, setLanguage] = React.useState(initialValues.lang);
    const [temperatureUnit, setTemperatureUnit] = React.useState(
        initialValues.temp
    );
    const cls = React.useRef(0);
    const clsLogged = React.useRef(false);
    const startTime = React.useRef(new Date().getTime());
    const sendClsData = useCallback((data) => {
        if (location?.pathname && location.pathname.includes("search-results")) {
            return;
        }
        const timeData = {
            ...data,
            time: new Date().getTime() - startTime.current,
            pathname: location?.pathname,
            scroll: window.scrollY,
            width: window.innerWidth,
            height: window.innerHeight
        };
        sendBeacon(timeData);
    }, []);
    useEffect(() => {
        try {
            const observer = new PerformanceObserver((entryList) => {
                try {
                    const entry = entryList.getEntries()[0];

                    // @ts-ignore
                    if (entry.hadRecentInput) {
                        return;
                    }
                    // @ts-ignore
                    const { value, sources } = entry;
                    cls.current += value;
                    

                    if ((value > 0.1 || cls.current > 0.1) && !clsLogged.current) {
                        sendClsData({
                            value: cls.current
                        });
                        clsLogged.current = true;
                    }
                } catch (e) {}
            });
            observer.observe({ type: "layout-shift", buffered: true });
            return () => {
                observer.disconnect();
            };
        } catch (e) {}
    }, []);

    return (
        <>
            <LanguageContext.Provider
                value={[language, (lang) => setLanguage(lang)]}
            >
                <TemperatureUnitContext.Provider
                    value={[
                        temperatureUnit,
                        (temp) => setTemperatureUnit(temp)
                    ]}
                >
                    {children}
                </TemperatureUnitContext.Provider>
            </LanguageContext.Provider>
        </>
    );
};

export const LanguageContext = createContext<[string, (a: string) => void]>([
    null,
    (_) => {}
]);
export const TemperatureUnitContext = createContext<
    [string, (a: string) => void]
>([null, (_) => {}]);
