export const sendBeacon = (data) => {
    try {
        if (navigator.sendBeacon) {
            let blob = new Blob([JSON.stringify(data)], {
                type: "application/json;charset=UTF-8"
            });

            navigator.sendBeacon(`/cls-data/`, blob);
            return;
        } else {
            const fetchProps = {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8"
                },
                body: JSON.stringify(data)
            };

            fetch(`/cls-data/`, fetchProps);
        }
    } catch (e) {}
};
